import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-rochester-new-york.png'
import image0 from "../../images/cities/scale-model-of-ontario-beach-park-in-rochester-new-york.png"
import image1 from "../../images/cities/scale-model-of-seneca-park-zoo-in-rochester-new-york.png"
import image2 from "../../images/cities/scale-model-of-charlotte-genesee-lighthouse-in-rochester-new-york.png"
import image3 from "../../images/cities/scale-model-of-maplewood-rose-garden-in-rochester-new-york.png"
import image4 from "../../images/cities/scale-model-of-bausch-&-lomb-riverside-park-in-rochester-new-york.png"
import image5 from "../../images/cities/scale-model-of-the-strong-museum-in-rochester-new-york.png"
import image6 from "../../images/cities/scale-model-of-seabreeze-amusement-park-in-rochester-new-york.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Rochester'
            state='New York'
            image={image}
            lat='44.0121221'
            lon='-92.4801989'
            attractions={ [{"name": "Ontario Beach Park", "vicinity": "50 Beach Ave, Rochester", "types": ["park", "point_of_interest", "establishment"], "lat": 43.2594928, "lng": -77.60694710000001}, {"name": "Seneca Park Zoo", "vicinity": "2222 St Paul St, Rochester", "types": ["zoo", "point_of_interest", "establishment"], "lat": 43.2050722, "lng": -77.62388659999999}, {"name": "Charlotte Genesee Lighthouse", "vicinity": "70 Lighthouse St, Rochester", "types": ["premise", "museum", "point_of_interest", "establishment"], "lat": 43.2527689, "lng": -77.61073540000001}, {"name": "Maplewood Rose Garden", "vicinity": "250 Maplewood Ave, Rochester", "types": ["park", "point_of_interest", "establishment"], "lat": 43.1814087, "lng": -77.6310598}, {"name": "Bausch & Lomb Riverside Park", "vicinity": "711 Wilson Blvd, Rochester", "types": ["park", "point_of_interest", "establishment"], "lat": 43.1320903, "lng": -77.6247113}, {"name": "The Strong Museum", "vicinity": "1 Manhattan Square Dr, Rochester", "types": ["museum", "point_of_interest", "establishment"], "lat": 43.1530253, "lng": -77.60087069999997}, {"name": "Seabreeze Amusement Park", "vicinity": "4600 Culver Rd, Rochester", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 43.2319762, "lng": -77.54239960000001}] }
            attractionImages={ {"Ontario Beach Park":image0,"Seneca Park Zoo":image1,"Charlotte Genesee Lighthouse":image2,"Maplewood Rose Garden":image3,"Bausch & Lomb Riverside Park":image4,"The Strong Museum":image5,"Seabreeze Amusement Park":image6,} }
       />);
  }
}